import React, { useState } from "react"
import { Link } from "gatsby"
import TagsWrapper from "../components/tags"
import styled from "styled-components"
import { useFlexSearch } from "react-use-flexsearch"
import * as queryString from "query-string"

import { rhythm } from "../utils/typography"

const SearchBar = styled.div`
  display: flex;
  margin: 0 auto ${rhythm(1)};
  margin-top: 180px;
  width: 80%;
  height: 3rem;
  background: #black;
  color: white;
  border: 1px solid var(--primary-blue);
  border-left: 1px solid var(--primary-blue);

  svg {
    margin: auto 1rem;
    height: 20px;
    width: 20px;
    color: #9aa0a6;
    fill: #9aa0a6;
  }

  input {
    display: flex;
    flex: 100%;
    height: 100%;
    width: 80%;
    font-size: 16px;
    background-color: black;
    border: none;
    margin: 0;
    padding: 0;
    padding-right: 0.5rem;
    color: white;
    word-wrap: break-word;
    outline: none;
    font-weight: bold;
  }
`

const SearchedPosts = ({ results }) => (
    <div id="searched-posts-container" className="container-xxl">
    <div className="row d-flex justify-content-center">
  {results.length > 0 ? (
    results.map(node => {
      const date = node.date
      const title = node.title || node.slug
      const description = node.description
      const excerpt = node.excerpt
      const slug = node.slug

      return (
        <Link className="col-lg-6 col-md-12 searched-blog-link d-flex" style={{ boxShadow: `none` }} to={`/blog${slug}`}>
        <div className="blog-post-details">
            <div className="blog-category">{node.category.toUpperCase()}</div>
            <h3 className="blog-post-title"
                style={{
                marginBottom: rhythm(1 / 4),
                }}
            >
        
                {title.trim()}<span className="color-primary">.</span>
            </h3>
            <p className="blog-date">{date}</p>
            <p className="text-paragraph"
                dangerouslySetInnerHTML={{
                __html: description || excerpt,
                }}
            /> 

            <TagsWrapper tags={node.tags.split(',')}></TagsWrapper>

            <div className="mt-5">
                <a className="body-link">
                    Read more &nbsp; <i class="fas fa-external-link-alt color-primary"></i>
                </a>
            </div>
        </div>
    </Link>
      )
    })
  ) : (
    <p style={{ textAlign: "center" }}>
      Sorry, couldn't find any posts matching this search.
    </p>
  )}
  </div>
  </div>
)

const AllPosts = ({ posts }) => (
    <div id="searched-posts-container" className="container-xxl">
        <div className="row d-flex justify-content-center">
        {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug

        return (
            <Link className="col-lg-6 col-md-12 searched-blog-link d-flex" style={{ boxShadow: `none` }} to={`/blog${node.fields.slug}`}>
                <div className="blog-post-details">
                    <div className="blog-category">{node.frontmatter.category.toUpperCase()}</div>
                    <h3 className="blog-post-title"
                        style={{
                        marginBottom: rhythm(1 / 4),
                        }}
                    >
                
                        {title.trim()}<span className="color-primary">.</span>
                    </h3>
                    <p className="blog-date">{node.frontmatter.date}</p>
                    <p className="text-paragraph"
                        dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                        }}
                    /> 

                    <TagsWrapper tags={node.frontmatter.tags.split(',')}></TagsWrapper>

                    <div className="mt-5">
                        <a className="body-link">
                            Read more &nbsp; <i class="fas fa-external-link-alt color-primary"></i>
                        </a>
                    </div>
                </div>
            </Link>
        )
        })}
    </div>
  </div>
)

const SearchPosts = ({ posts, localSearchBlog, location, navigate }) => {
  const { search } = queryString.parse(location.search)
  const [query, setQuery] = useState(search || "")

  const results = useFlexSearch(
    query,
    localSearchBlog.index,
    JSON.parse(localSearchBlog.store)
  )

  return (
    <>
      <SearchBar>
        <svg
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
        </svg>
        <input
          id="search"
          type="search"
          placeholder="Search all posts"
          value={query}
          onChange={e => {
            navigate(
              e.target.value ? `/blog/?search=${e.target.value}` : "/blog/"
            )
            setQuery(e.target.value)
          }}
        />
      </SearchBar>
      
      <div className="text-center">
        <TagsWrapper tags={['CASE STUDY', 'CIVIL & HUMAN RIGHTS', 'REPORT', 'ENVIRONMENT', 'PERSONAL']}></TagsWrapper>
      </div>
      {query ? <SearchedPosts results={results} /> : <AllPosts posts={posts} />}
    </>
  )
}

export default SearchPosts
