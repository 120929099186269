import React from "react"
import styled from "styled-components"

const Tags = props => {

    return (
        <TagsWrapper id={props.id} props={props}>
            {props.tags.map((tag, i) => {
                let tagLink = `/blog/?search=${encodeURIComponent(tag)}`;

                return (
                    <Tag href={tagLink}>{tag.toUpperCase()}</Tag>
                )
            })}
        </TagsWrapper>
    )
}

const Tag = styled.a`
    position: relative;
    z-index: 1;
    display: inline-block;
    color: #ff4d4d;
    background: #black;
    box-shadow: none;
    text-decoration: none;
    color: white;
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 15px !important;
    border: 1px solid var(--primary-blue);
    background: black;
    margin-top: 5px;
    transition: 0.3s;

        &:hover {
            cursor: pointer;
            color: white;
            background: var(--primary-blue);
            text-decoration: none;
            transition: 0.3s;   
        }

    @media (max-width: 768px) {
        font-size: 10px;
        padding: 3px 8px;
    }
`

const TagsWrapper = styled.div`
    display: block;
`

export default Tags
